

import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Kur from "@/components/inputs/Kur.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";

@Component({
  components: {Kur, FormWrapper}
})
export default class TakipKurForm extends Mixins(SimpleInputMixin) {
  @Prop() takip!: TakipEntity;
  @Ref('form') form!: FormWrapper;
  StatusCodeHelper = new StatusCodeHelper();

  localValue!: Array<TakipTarihliKurEntity>;
  ready = false;

  get isTakipTarihliKur() {
    if (this.localValue[0]?.tarih == this.takip.takip_tarihi)
      return true
    else
      return false
  }

  @Watch('takip.talepler')
  onTaleplerChange() {
    this.setKurAlanlari();
  }

  mounted() {
    this.ready = false;
    this.setKurAlanlari();
    this.ready = true;
  }

  setKurAlanlari() {
    let dovizler: Array<{ para_birimi_id: number, para_birimi: ParaBirimiEntity }> = [];
    this.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      talep.alacaklar.forEach((alacak: AlacakEntity) => {
        if (!dovizler.find((doviz) => (doviz.para_birimi_id == alacak.para_birimi_id)) && alacak.para_birimi_id && alacak.para_birimi_id != ParaBirimi.TRY)
          dovizler.push({para_birimi_id: alacak.para_birimi_id, para_birimi: alacak.para_birimi});
        alacak.asil_alacaklar.forEach((alacakKalemi: AlacakKalemiEntity) => {
          if (!dovizler.find((doviz) => (doviz.para_birimi_id == alacakKalemi.para_birimi_id)) && alacakKalemi.para_birimi_id &&  alacakKalemi.para_birimi_id != ParaBirimi.TRY)
            dovizler.push({para_birimi_id: alacakKalemi.para_birimi_id, para_birimi: alacakKalemi.para_birimi});
        })
      })
    });
    this.localValue.forEach((kur: TakipTarihliKurEntity, index) => {
      if (!dovizler.find((doviz) => (doviz.para_birimi_id == kur.para_birimi_id)))
        this.$http.delete('/api/v1/takip-tarihli-kur/' + kur.id).then(()=>(this.localValue.splice(index,1)));
    })
    dovizler.forEach((doviz) => {
      if (!this.localValue.find((kur) => (kur.para_birimi_id == doviz.para_birimi_id))) {
        let kur = new TakipTarihliKurEntity();
        kur.para_birimi = doviz.para_birimi;
        kur.para_birimi_id = doviz.para_birimi_id;
        kur.tutar = null;
        this.localValue.push(kur);
      }
    })
  }

  async save() {
    this.form.onFly = true;
    this.localValue.forEach((kur) => {
      kur.tarih = this.takip.takip_tarihi;
    });
    for (const kur of this.localValue) {
      if (kur.id) {
        this.$http.put('/api/v1/takip-tarihli-kur/' + kur.id, kur)
            .catch((error) => this.StatusCodeHelper.error(error));
      } else {
        this.$http.post('/api/v1/takip/' + this.takip.id + '/takip-tarihli-kur', kur)
            .catch(error => this.StatusCodeHelper.error(error));
      }
    }
    this.form.setDefaultLocalValue();
    this.form.onFly = false;
    this.$emit('onSuccess');
  }
}
